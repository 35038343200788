<template>
    <div class="head_box">
        <div class="head_left">
            <img src="../assets/images/minipass_purple.png" width="172" height="28" />
            <div>admin</div>
        </div>
        <div class="head_nav">
            <a-menu id="menu" mode="horizontal" @click="handleClick" v-model="selectedKeys">
                <template v-for="item in new_menu_list">
                    <a-menu-item v-if="!item.children" v-bind:key="item.name" :title="item.path">
                        <span>{{ item.name }}</span>
                    </a-menu-item>
                    <a-sub-menu v-if="item.children" v-bind:key="item.name">
                        <span slot="title" class="submenu-title-wrapper">{{ item.name }}<a-icon
                                type="caret-down" /></span>
                        <template v-for="cItem in item.children">
                            <a-menu-item v-if="!cItem.children && !cItem.hidden" :key="cItem.name" :title="cItem.path"
                                :disabled="cItem.disabled">
                                <span>{{ cItem.name }}</span>
                            </a-menu-item>
                            <a-sub-menu v-if="cItem.children" v-bind:key="cItem.name">
                                <span slot="title" class="submenu-title-wrapper">{{ cItem.name }}</span>
                                <template v-for="childItem in cItem.children">
                                    <a-menu-item v-if="!childItem.hidden" :key="childItem.name" :title="childItem.path"
                                        :disabled="childItem.disabled">
                                        <span>{{ childItem.name }}</span>
                                    </a-menu-item>
                                </template>
                            </a-sub-menu>
                        </template>
                    </a-sub-menu>
                </template>
            </a-menu>
        </div>
        <div class="head_right">
            <a-dropdown id="dropdown" overlayClassName="aa">
                <div>
                    <a-avatar icon="user" />
                    <div>{{ uname }}</div>
                </div>
                <a-menu id="logout" slot="overlay">
                    <a-menu-item>
                        <div class="l_text" @click="signout">
                            <span>Sign out</span>
                            <span class="icon"><a-icon type="export" /></span>
                        </div>
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
    </div>
</template>

<script>
export default {
    name: 'topbar',
    components: {},
    data() {
        return {
            uname: '',
            selectedKeys: ['User Feedback'],
            menu_list: [
                // {
                //     path: '/dashboard',
                //     name: 'Dashboard'
                // },
                {
                    path: '/contentCenter',
                    name: 'Content Center'
                },
                {
                    path: '/guests',
                    name: 'User Center',
                    children: [
                        {
                            path: '/guests',
                            name: 'User management'
                        },
                        {
                            path: '',
                            name: 'Account deletion',
                            disabled: true
                        },
                        {
                            path: '/feedback',
                            name: 'User Feedback'
                        },
                        {
                            path: '/survey',
                            name: 'User Survey'
                        }
                    ]
                },
                {
                    path: '/drawManagement',
                    name: 'Merchant Center',
                    children: [
                        {
                            path: '',
                            name: 'Request center',
                            disabled: true
                        },
                        {
                            path: '',
                            name: 'Draws',
                            children: [
                                {
                                    path: '/drawManagement',
                                    name: 'Event management'
                                },
                                {
                                    path: '/drawOrder',
                                    name: 'Draw orders'
                                },
                            ]
                        },
                        {
                            path: '/createDraw',
                            name: 'Draw management',
                            hidden: true
                        },
                        {
                            path: '/master/list',
                            name: 'Master Account'
                        },
                        {
                            path: '/market/boost/merchants',
                            name: 'Boost'
                        }
                    ]
                },
                {
                    path: '/adlist',
                    name: 'Marketing Center',
                    children: [
                        {
                            path: '/adlist',
                            name: 'Ad management'
                        },
                        {
                            path: '/market/survey/list',
                            name: 'Survey management'
                        },
                        {
                            path: '/market/list',
                            name: 'Push management'
                        },
                    ]
                },
                {
                    path: '/monitor/system',
                    name: 'Monitor',
                    children: [
                        {
                            path: '/monitor/system',
                            name: 'System Status'
                        },
                        {
                            path: '/monitor/bird',
                            name: 'Bird Message Status'
                        },
                        // {
                        //     path: '/monitor/waitingtime',
                        //     name: 'Estimated Waiting Time '
                        // }
                    ]
                }
            ],
            role: this.$store.state.app.role
        };
    },
    computed: {
        new_menu_list() {
            if (this.role === 'admin') {
                let new_list = JSON.parse(JSON.stringify(this.menu_list));

                new_list[1].children.splice(0,1);

                return new_list;
            } else if (this.role === 'superadm') {
                return this.menu_list;
            } 
        
            return [
                {
                    path: '/drawManagement',
                    name: 'Merchant Center',
                    children: [
                        {
                            path: '/master/list',
                            name: 'Master Account'
                        }
                    ]
                },
                {
                    path: '/monitor/system',
                    name: 'Monitor',
                    children: [
                        {
                            path: '/monitor/system',
                            name: 'System Status'
                        },
                        {
                            path: '/monitor/bird',
                            name: 'Bird Message Status'
                        }
                    ]
                }
            ];
        }
    },
    created() {
        this.parseMenu(this.menu_list);
        let _uname = window.localStorage.getItem('uname') || '';
        this.uname = _uname;
    },
    mounted() { },
    methods: {
        parseMenu(menu) {
            for (let i = 0; i < menu.length; i++) {
                if (menu[i].children) {
                    this.parseMenu(menu[i].children);
                } else {
                    if (this.$route.path == menu[i].path) {
                        this.selectedKeys = [menu[i].name];
                        break;
                    }
                }
            }
        },
        handleClick(e) {
            // console.log(e)
            if (e.keys === 'Draws') {
                return;
            }
            this.$router.replace({
                path: e.item.title
            });
        },
        signout() {
            window.localStorage.clear();
            this.$router.replace({
                path: '/login'
            });
        }
    }
};
</script>

<style scoped>
[role=menu] .ant-menu-item-selected,
[role=menu] .ant-menu-item-active,
[role=menu] .ant-menu-item-title:hover {
  color: #7427ff;
}

.head_box {
  color: #000;
  padding: 44px 45px 11px 45px;
  margin-bottom: 5px;
  /* position: relative; */
  height: 115px;
  box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.5);
  display: flex;
  justify-content: space-between;
  background-color: #f9fbfd;
}
.head_box .head_left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 24px;
}
.head_box .head_nav {
  margin-left: 50px;
}
.head_box .head_nav #menu {
  font-size: 18px;
  color: #000;
  border-bottom: none;
  background-color: #f9fbfd;
}
.head_box .head_nav #menu .ant-menu-item,
.head_box .head_nav #menu .ant-menu-submenu {
  border-bottom: none;
  width: 180px;
}
.head_box .head_nav #menu ::v-deep .ant-menu-item-selected,
.head_box .head_nav #menu ::v-deep .ant-menu-item-active,
.head_box .head_nav #menu ::v-deep .ant-menu-item-title:hover {
  color: #7427ff;
}
.head_box .head_nav #menu ::v-deep .ant-menu-submenu-selected,
.head_box .head_nav #menu ::v-deep .ant-menu-submenu-open,
.head_box .head_nav #menu ::v-deep .ant-menu-submenu-active,
.head_box .head_nav #menu ::v-deep .ant-menu-submenu-title:hover {
  color: #7427ff;
}
.head_box .head_right {
  font-size: 14px;
  color: #898989;
  text-align: center;
}
.head_box .head_right .ant-avatar {
  line-height: 25px;
  color: #898989;
  background: #fff;
  border: 1.5px solid #898989;
}
.head_box .head_right .ant-avatar-icon {
  font-size: 20px;
}

.aa .l_text {
  width: 120px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #020202;
}
.aa .l_text .icon {
  font-size: 18px;
  margin-left: 30px;
}
</style>