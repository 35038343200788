<template>
    <div class="main_bd">
        <div class="head_title">
            Waitlist Waiting Time Estimate
            <span style="font-size: 16px;">(refresh every minute)</span>
            <span>
                <a-spin v-if="is_loading" style="margin-left: 20px;"/>
            </span>
        </div>
        <div style="margin-top: 20px;">
            <span>Choose Merchant By Name</span>
            <a-input style="display: inline-block; width: 150px; margin-left: 10px;" v-model="sname" placeHolder="店铺关键词" />
            <a-select v-model="sid" style="margin-left: 10px; width: 300px" @change="handleChange">
                <a-select-option :value="item.sid" v-bind:key="item.sid" v-for="item in store_list">
                    {{ item.sname }}
                </a-select-option>
            </a-select>
            <a-button style="margin-left: 5px;" @click="doGetWaitingData">Refresh</a-button>
        </div>
        <a-row :gutter="16" style="margin: 20px 0;">
            <a-col :span="9">
                <a-card title="Table List">
                    <a-table :pagination="false" :rowKey="data => data.real_tid" :columns="columns" :data-source="table_list" >
                        <template slot="status_zone" slot-scope="text, data">
                            <a-tag color="green" v-if="data.real_tstatus == 1 || data.real_tstatus == 0">
                                空闲中
                            </a-tag>
                            <a-tag color="red" v-else-if="data.real_tstatus == 2 || data.real_tstatus == 3">
                                服务中
                            </a-tag>
                        </template>
                    </a-table>
                </a-card>
            </a-col>
            <a-col :span="5">
                <a-card :title="wait_list[0] && wait_list[0].name + ' - Party Type' || ' '">
                    <a-table :pagination="false" :rowKey="data => data.k" :columns="code_columns" :data-source="wait_list[0] && wait_list[0].list || []" >
                    </a-table>
                </a-card>
            </a-col>
            <a-col :span="5">
                <a-card :title="wait_list[1] && wait_list[1].name + ' - Party Type' || ' '">
                    <a-table :pagination="false" :rowKey="data => data.k" :columns="code_columns" :data-source="wait_list[1] && wait_list[1].list || []" >
                    </a-table>
                </a-card>
            </a-col>
            <a-col :span="5">
                <a-card :title="wait_list[2] && wait_list[2].name + ' - Party Type' || ' '">
                    <a-table :pagination="false" :rowKey="data => data.k" :columns="code_columns" :data-source="wait_list[2] && wait_list[2].list || []" >
                    </a-table>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    const columns = [
        {
            title: "Table Name",
            dataIndex: "real_tname"
        },
        {
            title: "Status",
            dataIndex: "real_tstatus",
            scopedSlots: { customRender: 'status_zone' },
        },
        // {
        //     title: "Estimated time",
        //     dataIndex: "estimated_time",
        // },
        {
            title: "End Time in",
            dataIndex: "expect_end_time"
        },
    ];
    const code_columns = [
        {
            title: "Estimated Waiting Time",
            dataIndex: "waiting_time"
        },
        {
            title: "Waiting Code",
            dataIndex: "wait_code"
        }
    ];

    export default {
        name: 'waiting_time',
        components: {
        },
        data() {
            return {
                columns,
                code_columns,
                is_loading: false,
                table_list: [],
                store_list: [],
                sid: '',

                wait_list: [],
                sname: ''
            };
        },
        async created() {
            this.do_get_merchant_list();
            await this.doGetWaitingData();

            this.timer = setInterval(() => {
                this.doGetWaitingData();
            }, 60000);
        },
        computed: {
            ...mapGetters({}),
            ...mapState({}),
        },
        watch: {
            sname() {
                this.do_get_merchant_list();
            }
        },
        methods: {
            ...mapActions({
                getBoostMerchantList: 'boost/getBoostMerchantList',
                getWaitingListData: 'monitor/getWaitingListData',
                getWaitingTimeData: 'monitor/getWaitingTimeData',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async doGetWaitingData() {
                if (!this.sid) {
                    return;
                }

                this.is_loading = true;
                let waiting_list_res = await this.getWaitingListData({ sid : this.sid });
                let waiting_time_data_res = await this.getWaitingTimeData({ sid : this.sid });

                let waiting_list = waiting_list_res.data.result;
                let waiting_time_data = waiting_time_data_res.data.result;
                this.table_list = waiting_time_data_res.data.table_list;

                this.wait_list = [];

                for (let party_type in waiting_time_data) {
                    let res = {
                        name: party_type,
                        list: []
                    };

                    for (let i = 0; i < waiting_time_data[party_type].length; i++) {
                        let item = {
                            waiting_time: waiting_time_data[party_type][i] + ' mins',
                            wait_code: waiting_list[party_type] ? (waiting_list[party_type][i] || '') : '',
                            k: party_type + '_' + i
                        };

                        res.list.push(item);
                    }

                    this.wait_list.push(res);
                }

                this.is_loading = false;
            },
            handleChange() {
                this.doGetWaitingData();
            },
            async do_get_merchant_list() {
                let { list } = await this.getBoostMerchantList({
                    sname: this.sname
                });

                this.store_list = list;
            },
        }
    };
</script>

<style>
    .custom_input{
        width: 220px !important;
    }
</style>